import React from "react";
import { useAppContext } from "@/presentation/providers/app-context-provider";
import { Button, SpaceBetween, Modal, Box } from "@cloudscape-design/components-themed/components";
const UnsavedChangesModal = ({ showDialog, cancel, confirm, }) => {
    const { dirtyFormResourceName } = useAppContext();
    const resourceMessage = dirtyFormResourceName ? `If you leave this page the ${dirtyFormResourceName} will not be saved.` : 'If you leave this page the changes will not be saved.';
    return (React.createElement(Modal, { visible: showDialog, onDismiss: cancel, header: React.createElement(React.Fragment, null,
            React.createElement("span", { style: { display: 'none' } },
                React.createElement(Button, null, "\u00A0")),
            React.createElement("span", null, "Changes will be lost")), closeAriaLabel: "Close dialog", "data-unsaved": "unsaved", footer: React.createElement(Box, { float: "right" },
            React.createElement(SpaceBetween, { direction: "horizontal", size: "xs" },
                React.createElement(Button, { variant: "link", onClick: cancel }, "Go Back"),
                React.createElement(Button, { onClick: confirm, variant: 'primary' }, "Discard Changes"))) },
        React.createElement(SpaceBetween, { size: "m" },
            React.createElement(Box, null, `${resourceMessage} Are you sure you want to discard your changes?`))));
};
export default UnsavedChangesModal;
