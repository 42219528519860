import React, { useEffect, useState } from "react";
import { SETTINGS_TABS } from "@/presentation/pages/settings/settings-types";
import { Button, Container, Form, FormField, Input, Select, SpaceBetween } from "@cloudscape-design/components-themed/components";
import { makeI18nStrings } from "@/presentation/components/I18nStrings";
import { PATHS } from "@/presentation/common-utils/constants";
import { useNavigate } from "react-router-dom";
import { getFieldOnChange } from "@/presentation/components";
import { makeNotification, makeProvidersSelectionOptions, PAGE_SPECIFIC_ERROR, PagesRequestErrorHandler } from "@/presentation/pages";
import { getContainerHeight, makeInvalidFormErrorMessage } from '@/presentation/common-utils/common-utils';
import { isEmptyObject } from "@/lib/misc/utils";
import { useSettingsHandlers } from "@/presentation/providers/settings-handlers-provider";
import { useNotifications } from "@/presentation/providers/notifications-provider";
import CodeEditor from "@/presentation/components/code-editor/code-editor";
import { useIsMounted } from '@/presentation/hooks';
const SettingsCloudTenantForm = (props) => {
    var _a, _b, _c, _d, _e, _f;
    const navigate = useNavigate();
    const i18nStrings = makeI18nStrings(props, "Cloud Tenant Structure");
    const { tenants, cloudTenantNameValidator } = useSettingsHandlers();
    const cloudTenantsHandler = tenants;
    const [providerError, setProviderError] = useState(false);
    const [structureError, setStructureError] = useState(false);
    const [name, setName] = useState((_b = (_a = props.cloudTenantsInfo) === null || _a === void 0 ? void 0 : _a.details) === null || _b === void 0 ? void 0 : _b.name);
    const [nameError, setNameError] = useState(false);
    const [selectedProvider, setSelectedProvider] = useState((_d = (_c = props.cloudTenantsInfo) === null || _c === void 0 ? void 0 : _c.details) === null || _d === void 0 ? void 0 : _d.provider);
    const providersOnChange = getFieldOnChange("select", "provider", props.onChange);
    const tenantStructureOnChange = getFieldOnChange("code", "tenantStructure", props.onChange);
    const nameOnChange = getFieldOnChange("text", "name", props.onChange);
    const providersListOptions = makeProvidersSelectionOptions(props.providersList);
    const [loading, setLoading] = useState(true);
    const { pushNotifications, dismissNotification } = useNotifications();
    const [editorHeight, setEditorHeight] = useState(400);
    const [currentRef, setCurrentRef] = useState(null);
    const ref = React.useRef(null);
    const [structureValue, setStructureValue] = useState(JSON.stringify((_f = (_e = props === null || props === void 0 ? void 0 : props.cloudTenantsInfo) === null || _e === void 0 ? void 0 : _e.details) === null || _f === void 0 ? void 0 : _f.tenantStructure, null, 2));
    const isMounted = useIsMounted();
    const updateEditorHeight = (updatedHeight) => {
        const removeCreateButtonDivSize = 100;
        // Approximate padding size around the create button container in pixels
        const newHeight = updatedHeight - removeCreateButtonDivSize;
        if (newHeight > editorHeight) {
            setEditorHeight(updatedHeight - removeCreateButtonDivSize);
        }
    };
    useEffect(() => {
        setCurrentRef(ref === null || ref === void 0 ? void 0 : ref.current);
    }, [ref === null || ref === void 0 ? void 0 : ref.current]);
    useEffect(() => {
        // The DOM element is accessible here.
        if (currentRef) {
            getContainerHeight('cloudTenantEditor', updateEditorHeight, editorHeight);
        }
    }, [currentRef]);
    const validatedOnProviderBlur = (val) => {
        const providerErrorStatus = (val === null || val === void 0 ? void 0 : val.constructor) === Object && Object.keys(val).length > 0;
        if (!providerErrorStatus) {
            setProviderError(true);
        }
    };
    const handleProviderChangeCallback = ({ detail }) => {
        var _a;
        if (((_a = detail.selectedOption) === null || _a === void 0 ? void 0 : _a.label) !== (selectedProvider === null || selectedProvider === void 0 ? void 0 : selectedProvider.label)) {
            providersOnChange(detail);
            setSelectedProvider(detail.selectedOption);
            setProviderError(false);
        }
    };
    const handleTenantStructureChange = (value) => {
        try {
            const parsedObj = JSON.parse(value);
            setStructureError(!value || isEmptyObject(parsedObj));
        }
        catch (e) {
            setStructureError(true);
        }
        setStructureValue(value);
        tenantStructureOnChange(value);
    };
    const handleNameChangeCallback = ({ detail }) => {
        setName(detail === null || detail === void 0 ? void 0 : detail.value);
        validatedOnNameBlur(detail === null || detail === void 0 ? void 0 : detail.value);
        nameOnChange(detail);
    };
    const validatedOnNameBlur = (val) => {
        const nValid = cloudTenantNameValidator === null || cloudTenantNameValidator === void 0 ? void 0 : cloudTenantNameValidator.validate(val);
        if (nValid === null || nValid === void 0 ? void 0 : nValid.valid) {
            setNameError(false);
        }
        else {
            setNameError(true);
        }
    };
    const makeSuccessNotification = (message) => {
        const msg = 'Cloud Tenant Structure ' + ((props.isCreate) ? 'Created' : 'Updated') + ' Successfully';
        return makeNotification("success", message || msg, dismissNotification);
    };
    const makeErrorNotification = (message) => {
        const msg = 'Unable to ' + ((props.isCreate) ? 'create' : 'update') + '. Please try again later.';
        return makeNotification("error", message || msg, dismissNotification);
    };
    const onCancel = () => {
        navigate(`${PATHS.SETTINGS}?tabId=${SETTINGS_TABS.CLOUD_TENANTS}`);
    };
    const handleErrorResponse = (response) => {
        const getResponseMessage = PagesRequestErrorHandler(response, PAGE_SPECIFIC_ERROR.CLOUD_TENANT_SUBMIT);
        pushNotifications(makeErrorNotification(getResponseMessage === null || getResponseMessage === void 0 ? void 0 : getResponseMessage.errorMessage));
    };
    const handleCreate = () => {
        var _a, _b, _c, _d, _e;
        if (isMounted.current) {
            setLoading(true);
        }
        cloudTenantsHandler === null || cloudTenantsHandler === void 0 ? void 0 : cloudTenantsHandler.create({
            name: name,
            organization_id: (_b = (_a = props.cloudTenantsInfo) === null || _a === void 0 ? void 0 : _a.details) === null || _b === void 0 ? void 0 : _b.orgID,
            provider_id: Number((_e = (_d = (_c = props.cloudTenantsInfo) === null || _c === void 0 ? void 0 : _c.details) === null || _d === void 0 ? void 0 : _d.provider) === null || _e === void 0 ? void 0 : _e.value),
            tenant_structure: JSON.parse(structureValue)
        }).then(result => {
            var _a;
            if (!((_a = result === null || result === void 0 ? void 0 : result.insert_CloudTenants_one) === null || _a === void 0 ? void 0 : _a.id)) {
                pushNotifications(makeErrorNotification());
            }
            else {
                pushNotifications(makeSuccessNotification());
                navigate(`${PATHS.SETTINGS}?tabId=${SETTINGS_TABS.CLOUD_TENANTS}`);
            }
        }).catch(({ response }) => {
            handleErrorResponse(response);
        }).finally(() => {
            if (isMounted.current) {
                setLoading(false);
            }
        });
    };
    const handleUpdate = () => {
        var _a, _b;
        if (isMounted.current) {
            setLoading(true);
        }
        cloudTenantsHandler === null || cloudTenantsHandler === void 0 ? void 0 : cloudTenantsHandler.update({
            name: name,
            id: (_b = (_a = props.cloudTenantsInfo) === null || _a === void 0 ? void 0 : _a.details) === null || _b === void 0 ? void 0 : _b.id,
            tenant_structure: JSON.parse(structureValue)
        }).then(result => {
            var _a;
            if (!((_a = result === null || result === void 0 ? void 0 : result.update_CloudTenants_by_pk) === null || _a === void 0 ? void 0 : _a.id)) {
                pushNotifications(makeErrorNotification());
            }
            else {
                pushNotifications(makeSuccessNotification());
                navigate(`${PATHS.SETTINGS}?tabId=${SETTINGS_TABS.CLOUD_TENANTS}`);
            }
        }).catch(({ response }) => {
            handleErrorResponse(response);
        }).finally(() => {
            if (isMounted.current) {
                setLoading(false);
            }
        });
    };
    const isFormValid = () => {
        const nameValid = cloudTenantNameValidator === null || cloudTenantNameValidator === void 0 ? void 0 : cloudTenantNameValidator.validate(name);
        setNameError(!(nameValid === null || nameValid === void 0 ? void 0 : nameValid.valid));
        const providerValid = (selectedProvider === null || selectedProvider === void 0 ? void 0 : selectedProvider.constructor) === Object && Object.keys(selectedProvider).length > 0;
        setProviderError(!providerValid);
        //  we can only check for empty object on the client side, let the server handle the format
        let isStructureError;
        try {
            const parsedObj = JSON.parse(structureValue);
            isStructureError = !structureValue || isEmptyObject(parsedObj);
        }
        catch (e) {
            isStructureError = true;
        }
        setStructureError(isStructureError);
        return (nameValid === null || nameValid === void 0 ? void 0 : nameValid.valid) && providerValid && !isStructureError;
    };
    const onSubmit = () => {
        if (isFormValid()) {
            if (props.isCreate) {
                handleCreate();
            }
            else {
                handleUpdate();
            }
        }
        else {
            pushNotifications(makeErrorNotification(makeInvalidFormErrorMessage('cloud tenant structure', props.isCreate ? 'create' : 'update')));
        }
    };
    return React.createElement("div", { className: 'form-container' },
        React.createElement(Form, { actions: React.createElement(SpaceBetween, { direction: "horizontal", size: "xs" },
                React.createElement(Button, { variant: "link", onClick: onCancel }, i18nStrings.cancelButton),
                React.createElement(Button, { variant: "primary", onClick: onSubmit }, i18nStrings.submitButton)) },
            React.createElement(Container, null,
                React.createElement(SpaceBetween, { size: "l" },
                    React.createElement(FormField, { label: "Name", errorText: nameError ? "Please enter a name for this cloud tenant structure" : "" },
                        React.createElement(Input, { placeholder: "Example Name", value: name, onChange: handleNameChangeCallback, onBlur: () => {
                                validatedOnNameBlur(name);
                            } })),
                    React.createElement(FormField, { label: "Provider", errorText: providerError ? 'Please select the cloud provider for this cloud tenant structure' : '' },
                        React.createElement(Select, { selectedOption: selectedProvider, onChange: handleProviderChangeCallback, options: providersListOptions, filteringType: "auto", selectedAriaLabel: "Selected", disabled: props.isEdit, placeholder: "Choose Provider", onBlur: () => {
                                validatedOnProviderBlur(selectedProvider);
                            } })),
                    React.createElement(FormField, { label: "Structure", errorText: structureError ? 'Please enter a valid cloud tenant structure' : '' },
                        React.createElement("div", { ref: ref, id: "cloudTenantEditor" },
                            React.createElement(CodeEditor, { loading: loading, code: structureValue, height: editorHeight, heightType: 'px', readOnly: false, language: 'cloud-tenant', onChange: handleTenantStructureChange })))))));
};
export default SettingsCloudTenantForm;
