import React, { useState, useEffect, useRef } from "react";
import { loader, Editor } from "@monaco-editor/react";
import { conf, getCodeEditorTheme, PlaceholderContentWidget, token } from "@/presentation/components/code-editor/utils";
import * as monaco from 'monaco-editor';
import { getCloudTenantSchemas } from "@/presentation/components/code-editor/schemas";
loader.config({ monaco });
const CodeEditor = (props) => {
    var _a, _b;
    const [value, setValue] = useState(props.code || "");
    const theme = (_a = props === null || props === void 0 ? void 0 : props.theme) !== null && _a !== void 0 ? _a : 'kivera-light';
    const editorRef = useRef(null);
    const language = props.language === 'cloud-tenant' ? 'json' : props.language;
    useEffect(() => {
        setValue(props.code);
    }, [props.code]);
    const handleMount = (editor, monaco) => {
        monaco.languages.register({
            id: "rego",
            extensions: [".rego"],
            aliases: ["Rego"],
        });
        monaco.languages.setLanguageConfiguration('rego', conf);
        monaco.languages.setMonarchTokensProvider("rego", token);
        if (props.language === 'cloud-tenant') {
            const { diagnosticsOptions } = monaco.languages.json.jsonDefaults;
            const schemas = getCloudTenantSchemas(diagnosticsOptions.schemas || []);
            monaco.languages.json.jsonDefaults.setDiagnosticsOptions(Object.assign(Object.assign({}, diagnosticsOptions), { validate: true, schemas, schemaValidation: 'error' }));
        }
        new PlaceholderContentWidget('', editor, monaco);
        const editorTheme = getCodeEditorTheme(theme);
        monaco.editor.defineTheme(theme, editorTheme);
        monaco.editor.setTheme(theme);
        editorRef.current = editor;
    };
    const handleEditorChange = (value) => {
        setValue(value);
        if (props === null || props === void 0 ? void 0 : props.onChange) {
            props.onChange(value);
        }
    };
    return (React.createElement("div", { className: " w-full h-full shadow-4xl" },
        React.createElement(Editor, { loading: (_b = props === null || props === void 0 ? void 0 : props.loading) !== null && _b !== void 0 ? _b : false, className: 'code-editor-container ' + ((props === null || props === void 0 ? void 0 : props.inverse) ? 'inverse' : ''), height: String((props === null || props === void 0 ? void 0 : props.height) + (props === null || props === void 0 ? void 0 : props.heightType)) || "30vh", width: (props === null || props === void 0 ? void 0 : props.width) || "100%", language: language, value: value, onChange: handleEditorChange, onMount: handleMount, options: {
                fontSize: 14,
                scrollBeyondLastLine: false,
                padding: {
                    top: 16
                },
                readOnly: props.readOnly || false,
                minimap: { enabled: (props === null || props === void 0 ? void 0 : props.miniMap) || true },
                // renderIndentGuides: true,
                suggest: {
                    showFields: false,
                    showFunctions: false
                },
                quickSuggestions: {
                    comments: false,
                    other: false,
                    strings: false,
                },
                parameterHints: {
                    enabled: false,
                },
                scrollbar: {
                    alwaysConsumeMouseWheel: false
                }
            } })));
};
export default CodeEditor;
