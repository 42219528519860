import React, { useEffect, useState } from "react";
import { Button } from "@cloudscape-design/components-themed/components";
import { useNotifications } from "@/presentation/providers/notifications-provider";
import { makeNotification } from "@/presentation/pages";
import { capitalizeFirstForcefully } from "@/lib/misc/utils";
import DeleteModal from "@/presentation/components/modal/delete-modal";
import { PagesRequestErrorHandler } from '@/presentation/pages/errors';
const DeleteRecord = (props) => {
    const [selectedItems, setSelectedItems] = useState([]);
    const { pushNotifications, dismissNotification } = useNotifications();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const onDeleteInit = () => setShowDeleteModal(true);
    const onDeleteDiscard = () => setShowDeleteModal(false);
    const [isDeleteDisabled, setIsDeleteDisabled] = useState(true);
    const makeSuccessNotification = (message) => {
        const msg = capitalizeFirstForcefully(selectedItems.length > 1
            ? props.deleteModalStrings.modalTextPlural
            : props.deleteModalStrings.modalTextSingular) + " deleted successfully";
        return makeNotification("success", message || msg, dismissNotification);
    };
    const makeErrorNotification = (message) => {
        const msg = "Unable to delete. Please try again later.";
        return makeNotification("error", message || msg, dismissNotification);
    };
    const handleErrorResponse = (response) => {
        const getResponseMessage = PagesRequestErrorHandler(response);
        pushNotifications(makeErrorNotification(getResponseMessage === null || getResponseMessage === void 0 ? void 0 : getResponseMessage.errorMessage));
    };
    const onDeleteConfirm = () => {
        var _a;
        const toBeDeleted = selectedItems.map((item) => item === null || item === void 0 ? void 0 : item.id);
        (_a = props.entityHandler[props.deleteFunctionName](toBeDeleted)) === null || _a === void 0 ? void 0 : _a.then((result) => {
            console.log(result);
            if (result === null || result === void 0 ? void 0 : result.success) {
                pushNotifications(makeSuccessNotification(props === null || props === void 0 ? void 0 : props.customMessage));
                props.onDelete();
            }
            else {
                pushNotifications(makeErrorNotification());
            }
        }).catch(({ response }) => {
            handleErrorResponse(response);
        }).finally(() => {
            setShowDeleteModal(false);
        });
    };
    useEffect(() => {
        var _a;
        setSelectedItems((props === null || props === void 0 ? void 0 : props.items) || []);
        setIsDeleteDisabled(((_a = props === null || props === void 0 ? void 0 : props.items) === null || _a === void 0 ? void 0 : _a.length) < 1);
    }, [props.items]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { variant: "normal", disabled: isDeleteDisabled, onClick: onDeleteInit }, "Delete"),
        React.createElement(DeleteModal, { visible: showDeleteModal, onDiscard: onDeleteDiscard, onDelete: onDeleteConfirm, items: selectedItems, deleteModalStrings: props.deleteModalStrings, itemDeleteShowProp: props.itemDeleteShowProp })));
};
export default DeleteRecord;
