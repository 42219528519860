import React, { useEffect, useState } from 'react';
import { paginationLabels } from '@/presentation/components/commons/labels';
import { useAuth0 } from '@/lib/auth0';
import { COLUMN_WIDTH_STORAGE_KEY, PREFERENCES_STORAGE_KEY } from '@/presentation/pages/constants-and-types';
import ContentWrapper from '@/presentation/components/content-wrapper';
import { MonitoringListDefaultDateRangeFilter, MonitoringListDefaultDatetimeOptions, generateFilterObject, MonitoringListInitialTableOptions, MonitoringListCardsColumnDefinitions, monitoringListFilteringOptions, MonitoringListFilteringProperties, MonitoringListTablePageSizeOptions, MonitoringListTablePreferences, MonitoringListTableVisibleContentOptions } from '@/presentation/pages/monitoring/monitoring-list-config';
import { useMonitoringHandlers } from '@/presentation/providers/monitoring-handlers-provider';
import { GetDateRangeChangeDetail, GetDateTimeOptions, InitialDateRangeOptions, SetDateRangeChangeDetail, SetDateTimeOptions } from '@/presentation/storage';
import { isEmptyObject, isJSON } from '@/lib/misc/utils';
import { useColumnWidths } from '@/presentation/components/commons/use-column-widths';
import { useLocalStorage } from '@/presentation/components/commons/localStorage';
import { useCollection } from '@cloudscape-design/collection-hooks';
import { TableHeader, TableNoMatchState } from '@/presentation/components/commons/common-components';
import { breadcrumbsMonitoring } from '@/presentation/components/commons/breadcrumbs';
import { KiverascapeLayout } from '@/presentation/layouts/kiverascape-layout';
import { useSplitPanel } from '@/presentation/components/commons/use-split-panel';
import { getMonitoringListSplitPanel } from '@/presentation/pages/monitoring/monitoring-list-split-panel';
import { deleteUrlParams, getDateTimeRangeOptions, getUrlParams, makeDateRangeI18nStrings, makeDateRangeRelativeOptions, makeNotification, makePropertyFilterI18nStrings, makeSplitPanelI18nStrings, setUrlParams, useQueryParams, validateDateRange } from '@/presentation/pages';
import { Box, Button, CollectionPreferences, DateRangePicker, Pagination, PropertyFilter, SpaceBetween, SplitPanel, Table, Flashbar, Icon } from '@cloudscape-design/components-themed/components';
import { getFilterCounterText } from '@/presentation/components/commons/tableCounterStrings';
import TableContainer from '@/presentation/components/table/table-container';
import { GetPropertyFilters, GetTableSortingColumn, SetPropertyFilters, SetTableSortingColumn } from '@/presentation/storage/misc-storage';
import { TABLE_FILTERS_SESSION_STORAGE, TABLE_SORTING_SESSION_STORAGE } from '@/presentation/common-utils/constants';
import moment from 'moment';
import { useHistory } from '@/lib/browser-history/history-provider';
import { useIsMounted } from '@/presentation/hooks';
import { fetchUniquePropertyFilters, uniqueAndSortedArrayOfObjectFilters } from '@/presentation/common-utils/common-utils';
import { useErrorBoundaryContext } from '@/presentation/providers/error-boundary-provider';
import { Tooltip } from '@/presentation/components/tooltip';
import { useNotifications } from '@/presentation/providers/notifications-provider';
import CopyLinkSVGIconUrl from '@/presentation/assets/img/icons/copy_link.svg';
import { renderTableActions } from '@/presentation/components/table/table-actions';
const MonitoringEmptyState = () => {
    return React.createElement(Box, { margin: { vertical: 'xs' }, textAlign: "center", color: "inherit" },
        React.createElement(SpaceBetween, { size: "xxs" },
            React.createElement(Box, null, "No monitoring logs found. Please create a proxy and send some traffic to see logs.")));
};
const MonitoringList = () => {
    var _a, _b, _c, _d, _e;
    const { history } = useHistory();
    let query = useQueryParams();
    const { user } = useAuth0();
    const [loading, setLoading] = useState(false);
    const [monitoringData, setMonitoringData] = useState([]);
    const [formattedMonitoringData, setFormattedMonitoringData] = useState();
    const [splitOpen, setSplitOpen] = useState(false);
    const [splitKeyValue, setSplitKeyValue] = useState(1);
    const onSplitPanelToggle = ({ detail: { open } }) => {
        setSplitKeyValue(splitKeyValue + 1);
        setSplitOpen(open);
        setSelectedItems([]);
    };
    const [selectedItems, setSelectedItems] = useState([]);
    const { monitoring } = useMonitoringHandlers();
    const monitoringHandler = monitoring;
    const isMounted = useIsMounted();
    const { setErrorStatus } = useErrorBoundaryContext();
    const [filteringOptions, setFilteringOptions] = useState(monitoringListFilteringOptions);
    const [renderCount, setRenderCount] = useState(1);
    const urlParamRequests = (type, name, value = null) => {
        switch (type) {
            case "create":
                setUrlParams(history, query, name, value);
                break;
            case "delete":
                deleteUrlParams(history, query, name);
                break;
        }
    };
    const handleOnClick = (itemDetail) => {
        setSplitKeyValue(splitKeyValue + 1);
        setSelectedItems([itemDetail]);
        setSplitOpen(true);
    };
    const updatedColumnDefinitions = [...MonitoringListCardsColumnDefinitions,
        {
            id: 'table-actions',
            header: '',
            cell: item => renderTableActions(handleOnClick, item)
        }
    ];
    const getDateTimeOptions = GetDateTimeOptions(MonitoringListInitialTableOptions.dateTimeOptionsStorage, MonitoringListDefaultDatetimeOptions);
    const getDateTimeRangeFilters = (_a = GetDateRangeChangeDetail(MonitoringListInitialTableOptions.dateRangeStorage, MonitoringListDefaultDateRangeFilter)) === null || _a === void 0 ? void 0 : _a.value;
    const queryStartTime = Number(getUrlParams(query, "from_ts"));
    const queryEndTime = Number(getUrlParams(query, "to_ts"));
    const setDateAndTimeObject = (timeRangeObject) => {
        if (timeRangeObject) {
            if (queryStartTime && queryEndTime) {
                const localStartTime = moment(queryStartTime).local().format();
                const localEndTime = moment(queryEndTime).local().format();
                return {
                    dateTime: {
                        startDateTime: localStartTime,
                        endDateTime: localEndTime
                    },
                    dateRange: {
                        startDate: localStartTime,
                        endDate: localEndTime,
                        type: 'absolute'
                    }
                };
            }
        }
        return undefined;
    };
    const queryDateTimeAndRange = setDateAndTimeObject(getDateTimeRangeFilters);
    const [dateTimeOptions, setDateTimeOptions] = useState((_b = queryDateTimeAndRange === null || queryDateTimeAndRange === void 0 ? void 0 : queryDateTimeAndRange.dateTime) !== null && _b !== void 0 ? _b : getDateTimeOptions);
    const [dateRangeFilter, setDateRangeFilter] = useState((_c = queryDateTimeAndRange === null || queryDateTimeAndRange === void 0 ? void 0 : queryDateTimeAndRange.dateRange) !== null && _c !== void 0 ? _c : getDateTimeRangeFilters);
    const refreshList = (sFilters) => {
        if (isMounted.current) {
            setLoading(true);
        }
        const listParams = Object.assign({ start_timestamp: dateTimeOptions.startDateTime, end_timestamp: dateTimeOptions.endDateTime, from: 0, size: 1000 }, (sFilters && !isEmptyObject(sFilters) && { filter: sFilters }));
        monitoringHandler === null || monitoringHandler === void 0 ? void 0 : monitoringHandler.listEntity(listParams).then((entityItems) => {
            const formattedItems = monitoringHandler === null || monitoringHandler === void 0 ? void 0 : monitoringHandler.getFormattedEntities(entityItems);
            if (isMounted.current) {
                setFormattedMonitoringData(formattedItems);
            }
        }).catch((e) => {
            if (isMounted.current) {
                setErrorStatus(true);
            }
        }).finally(() => {
            if (isMounted.current) {
                setLoading(false);
                setSplitKeyValue(splitKeyValue + 1);
            }
        });
    };
    const [columnDefinitions] = useColumnWidths(COLUMN_WIDTH_STORAGE_KEY.MONITORING_LIST, updatedColumnDefinitions);
    const [preferences, setPreferences] = useLocalStorage(PREFERENCES_STORAGE_KEY.MONITORING_LIST, MonitoringListTablePreferences);
    const getSessionPropertyFilter = GetPropertyFilters(TABLE_FILTERS_SESSION_STORAGE.MONITORING_LIST);
    const getSessionPropertySorting = GetTableSortingColumn(TABLE_SORTING_SESSION_STORAGE.MONITORING_LIST);
    const getDefaultSortingState = () => {
        var _a;
        const sortingField = getUrlParams(query, 'sort_field');
        const sortingOrder = getUrlParams(query, 'sort_order');
        if (sortingField) {
            const sortObject = {
                sortingColumn: { sortingField },
                isDescending: sortingOrder === 'desc'
            };
            SetTableSortingColumn(TABLE_SORTING_SESSION_STORAGE.MONITORING_LIST, sortObject);
            return sortObject;
        }
        else if (getSessionPropertySorting) {
            return {
                sortingColumn: { sortingField: (_a = getSessionPropertySorting === null || getSessionPropertySorting === void 0 ? void 0 : getSessionPropertySorting.sortingColumn) === null || _a === void 0 ? void 0 : _a.sortingField },
                isDescending: (getSessionPropertySorting === null || getSessionPropertySorting === void 0 ? void 0 : getSessionPropertySorting.isDescending) || true
            };
        }
        return undefined;
    };
    const fetchFiltersObject = () => {
        var _a, _b;
        const paramObject = getUrlParams(query, 'filters');
        const filtersParamObject = paramObject && isJSON(paramObject) ? JSON.parse(paramObject) : null;
        if (((_a = filtersParamObject === null || filtersParamObject === void 0 ? void 0 : filtersParamObject.filter_items) === null || _a === void 0 ? void 0 : _a.length) > 0) {
            const getNeneratedFilterObject = generateFilterObject(filtersParamObject === null || filtersParamObject === void 0 ? void 0 : filtersParamObject.filter_items, "property", "propertyKey");
            const filterObject = {
                operation: (filtersParamObject === null || filtersParamObject === void 0 ? void 0 : filtersParamObject.operation) || 'and',
                tokens: getNeneratedFilterObject || []
            };
            SetPropertyFilters(TABLE_FILTERS_SESSION_STORAGE.MONITORING_LIST, filterObject);
            return filterObject;
        }
        else if (((_b = getSessionPropertyFilter === null || getSessionPropertyFilter === void 0 ? void 0 : getSessionPropertyFilter.tokens) === null || _b === void 0 ? void 0 : _b.length) > 0) {
            return getSessionPropertyFilter;
        }
        return { tokens: [], operation: 'and' };
    };
    const filterQueryObject = fetchFiltersObject();
    const filterSort = getDefaultSortingState();
    const defaultSortingState = {
        sortingColumn: (_d = filterSort === null || filterSort === void 0 ? void 0 : filterSort.sortingColumn) !== null && _d !== void 0 ? _d : updatedColumnDefinitions === null || updatedColumnDefinitions === void 0 ? void 0 : updatedColumnDefinitions[1],
        isDescending: (_e = filterSort === null || filterSort === void 0 ? void 0 : filterSort.isDescending) !== null && _e !== void 0 ? _e : true
    };
    const { items, actions, filteredItemsCount, collectionProps, propertyFilterProps, paginationProps } = useCollection(monitoringData, {
        propertyFiltering: {
            defaultQuery: filterQueryObject,
            filteringProperties: MonitoringListFilteringProperties,
            empty: React.createElement(MonitoringEmptyState, null),
            noMatch: React.createElement(TableNoMatchState, { resourceName: "monitoring logs" })
        },
        pagination: { pageSize: preferences.pageSize },
        sorting: { defaultState: defaultSortingState },
        selection: {}
    });
    const [panelPreferences, setPanelPreferences] = useState({ position: 'side' });
    const dateRangeOnChange = (detail) => {
        const dateTimeOptions = getDateTimeRangeOptions(detail);
        if (detail === null || detail === void 0 ? void 0 : detail.value) {
            SetDateRangeChangeDetail(MonitoringListInitialTableOptions.dateRangeStorage, detail);
        }
        else {
            urlParamRequests("delete", "dateTime");
            urlParamRequests("delete", "dateRange");
            SetDateRangeChangeDetail(MonitoringListInitialTableOptions.dateRangeStorage, InitialDateRangeOptions);
        }
        const dateTimeValue = (detail === null || detail === void 0 ? void 0 : detail.value) || (MonitoringListDefaultDateRangeFilter === null || MonitoringListDefaultDateRangeFilter === void 0 ? void 0 : MonitoringListDefaultDateRangeFilter.value);
        setDateRangeFilter(dateTimeValue);
        setDateTimeOptions(dateTimeOptions);
        SetDateTimeOptions(MonitoringListInitialTableOptions.dateTimeOptionsStorage, dateTimeOptions);
    };
    const handleOnChangePanelPreferences = (preference) => {
        if (preference === null || preference === void 0 ? void 0 : preference.detail) {
            setPanelPreferences(preference.detail);
        }
    };
    const { header: panelHeader, body: panelBody } = getMonitoringListSplitPanel(selectedItems);
    const { splitPanelSize, onSplitPanelResize } = useSplitPanel(collectionProps.selectedItems, splitOpen, onSplitPanelToggle);
    const updatedFiltersList = (tokensArray = []) => {
        const uniqueActionsArray = [];
        const existingActionsObject = (formattedMonitoringData === null || formattedMonitoringData === void 0 ? void 0 : formattedMonitoringData.serviceActionFilterObject) || {};
        tokensArray === null || tokensArray === void 0 ? void 0 : tokensArray.filter(item => (item === null || item === void 0 ? void 0 : item.propertyKey) === 'service').forEach(serv => {
            if (existingActionsObject[serv.value]) {
                uniqueActionsArray.push(...existingActionsObject[serv.value]);
            }
        });
        const uniqueActionFilterOptions = uniqueAndSortedArrayOfObjectFilters(uniqueActionsArray, 'action');
        return uniqueActionFilterOptions;
    };
    const { notifications, pushNotifications, dismissNotification } = useNotifications();
    const [querySelectedId, setQuerySelectedId] = useState(getUrlParams(query, 'xkid'));
    const makeSuccessNotification = (message) => {
        const msg = "Link copied successfully.";
        return makeNotification("success", message || msg, dismissNotification);
    };
    const makeErrorNotification = (message) => {
        const msg = "Unable to copy link. Please try again later.";
        return makeNotification("error", message || msg, dismissNotification);
    };
    useEffect(() => {
        if (querySelectedId && monitoringData.length > 0) {
            const findElem = monitoringData.find((element) => element.xkid === querySelectedId);
            setSelectedItems([findElem !== null && findElem !== void 0 ? findElem : { id: 'unavailable' }]);
            setSplitOpen(true);
            setSplitKeyValue(splitKeyValue + 1);
        }
    }, [monitoringData]);
    useEffect(() => {
        if (formattedMonitoringData) {
            const { formattedFilteredList } = formattedMonitoringData;
            let uniqueActionFilterOptions = [];
            if (filterQueryObject === null || filterQueryObject === void 0 ? void 0 : filterQueryObject.tokens) {
                uniqueActionFilterOptions = updatedFiltersList(filterQueryObject === null || filterQueryObject === void 0 ? void 0 : filterQueryObject.tokens);
            }
            const updateFilters = [...monitoringListFilteringOptions, ...formattedFilteredList, ...uniqueActionFilterOptions];
            setFilteringOptions(updateFilters);
            if (formattedMonitoringData === null || formattedMonitoringData === void 0 ? void 0 : formattedMonitoringData.monitoringData) {
                setMonitoringData(formattedMonitoringData.monitoringData);
            }
        }
    }, [formattedMonitoringData]);
    useEffect(() => {
        refreshList();
        urlParamRequests("delete", "xkid");
        urlParamRequests("delete", "from_ts");
        urlParamRequests("delete", "to_ts");
        urlParamRequests("delete", "sort_field");
        urlParamRequests("delete", "sort_order");
        urlParamRequests("delete", "filters");
    }, [user]);
    const handleSplitPanelResize = (evt) => {
        var _a;
        setSplitKeyValue(splitKeyValue + (((_a = evt === null || evt === void 0 ? void 0 : evt.detail) === null || _a === void 0 ? void 0 : _a.size) || 1));
        onSplitPanelResize(evt);
    };
    const handleSortingChange = ({ detail }) => {
        SetTableSortingColumn(TABLE_SORTING_SESSION_STORAGE.MONITORING_LIST, detail);
        actions.setSorting(detail);
    };
    const handleFiltersOnChange = ({ detail }) => {
        const filterActionTypes = filteringOptions.filter(item => item.propertyKey !== "action");
        if ((detail === null || detail === void 0 ? void 0 : detail.tokens.length) > 0) {
            const uniqueActionFilterOptions = updatedFiltersList(detail === null || detail === void 0 ? void 0 : detail.tokens);
            setFilteringOptions([...filterActionTypes, ...uniqueActionFilterOptions]);
        }
        else {
            setFilteringOptions(filterActionTypes);
        }
        setSelectedItems([]);
        const filteredDetail = fetchUniquePropertyFilters(detail);
        SetPropertyFilters(TABLE_FILTERS_SESSION_STORAGE.MONITORING_LIST, filteredDetail);
        actions.setPropertyFiltering(filteredDetail);
        setSplitKeyValue(splitKeyValue + 1);
    };
    const copyToClipBoard = () => {
        var _a, _b, _c;
        try {
            const getNeneratedFilterObject = generateFilterObject(getSessionPropertyFilter === null || getSessionPropertyFilter === void 0 ? void 0 : getSessionPropertyFilter.tokens, "propertyKey", "property");
            const filterObject = {
                operation: (getSessionPropertyFilter === null || getSessionPropertyFilter === void 0 ? void 0 : getSessionPropertyFilter.operation) || 'and',
                filter_items: getNeneratedFilterObject || []
            };
            const epochStartTime = dateRangeFilter.type === "relative" ? moment(Date.now()).subtract(dateRangeFilter.amount, `${dateRangeFilter.unit}s`).local().valueOf() : moment(dateRangeFilter.startDate).local().valueOf();
            const epochEndTime = dateRangeFilter.type === "relative" ? moment(Date.now()).local().valueOf() : moment(dateRangeFilter.endDate).local().valueOf();
            const sortField = (_a = getSessionPropertySorting === null || getSessionPropertySorting === void 0 ? void 0 : getSessionPropertySorting.sortingColumn) === null || _a === void 0 ? void 0 : _a.sortingField;
            const sortOrder = (getSessionPropertySorting === null || getSessionPropertySorting === void 0 ? void 0 : getSessionPropertySorting.isDescending) ? 'desc' : 'asc';
            let permaLinkUrl = location.href;
            let isAppendAndSymbolApplied = false;
            if (selectedItems.length === 1) {
                permaLinkUrl += `?xkid=${(_b = selectedItems === null || selectedItems === void 0 ? void 0 : selectedItems[0]) === null || _b === void 0 ? void 0 : _b.xkid}`;
                isAppendAndSymbolApplied = true;
            }
            const timeData = `from_ts=${epochStartTime}&to_ts=${epochEndTime}`;
            permaLinkUrl += isAppendAndSymbolApplied ? `&${timeData}` : `?${timeData}`;
            if (sortField) {
                permaLinkUrl += `&sort_field=${sortField}&sort_order=${sortOrder}`;
            }
            if (((_c = getSessionPropertyFilter === null || getSessionPropertyFilter === void 0 ? void 0 : getSessionPropertyFilter.tokens) === null || _c === void 0 ? void 0 : _c.length) > 0) {
                permaLinkUrl += `&filters=${JSON.stringify(filterObject)}`;
            }
            navigator.clipboard.writeText(encodeURI(permaLinkUrl));
            pushNotifications(makeSuccessNotification());
        }
        catch (e) {
            pushNotifications(makeErrorNotification());
        }
    };
    return React.createElement(React.Fragment, null,
        React.createElement(KiverascapeLayout, { breadcrumbMenu: breadcrumbsMonitoring, splitPanelOpen: splitOpen, onSplitPanelToggle: onSplitPanelToggle, splitPanelSize: splitPanelSize, onSplitPanelResize: handleSplitPanelResize, splitPanelPreferences: panelPreferences, onSplitPanelPreferencesChange: handleOnChangePanelPreferences, splitPanel: panelBody && selectedItems.length > 0 &&
                (React.createElement(SplitPanel, { header: panelHeader, i18nStrings: makeSplitPanelI18nStrings(), closeBehavior: "hide" }, panelBody)) || null, contentType: "default", notifications: React.createElement(Flashbar, { items: notifications, 
                // @ts-ignore
                stackItems: true }), content: React.createElement(ContentWrapper, { testid: "monitoring-list-items", page: "monitoring" },
                React.createElement(TableContainer, { key: renderCount },
                    React.createElement(Table, Object.assign({}, collectionProps, { "data-name": loading ? '' : "splitpanel-table", key: splitKeyValue, resizableColumns: true, loading: loading, loadingText: 'Loading Logs...', columnDefinitions: columnDefinitions, visibleColumns: preferences.visibleContent, items: items, ariaLabels: {}, variant: "full-page", selectionType: 'single', wrapLines: preferences.wrapLines, selectedItems: selectedItems, onSortingChange: handleSortingChange, header: React.createElement(TableHeader, { variant: 'awsui-h1-sticky', title: 'Monitoring' }), filter: React.createElement(React.Fragment, null,
                            React.createElement(SpaceBetween, { size: 'l', direction: 'horizontal' },
                                React.createElement("div", { className: 'list-property-filter' },
                                    React.createElement(PropertyFilter, Object.assign({}, propertyFilterProps, { i18nStrings: makePropertyFilterI18nStrings({ filteringPlaceholder: 'Filter Logs' }), countText: getFilterCounterText(filteredItemsCount), expandToViewport: true, filteringOptions: filteringOptions, filteringProperties: MonitoringListFilteringProperties, onChange: handleFiltersOnChange }))),
                                React.createElement(DateRangePicker, { onChange: ({ detail }) => {
                                        dateRangeOnChange(detail);
                                    }, value: dateRangeFilter, relativeOptions: makeDateRangeRelativeOptions(), isValidRange: validateDateRange, i18nStrings: makeDateRangeI18nStrings(), placeholder: "Search by date and time range", showClearButton: true }),
                                React.createElement(Button, { variant: "primary", onClick: () => {
                                        setSelectedItems([]);
                                        setQuerySelectedId(null);
                                        refreshList();
                                    } }, "Search"),
                                React.createElement("button", { className: 'p-1.5 monitoringCopyLink', onClick: () => copyToClipBoard(), tabIndex: 0 },
                                    React.createElement(Icon, { url: CopyLinkSVGIconUrl }),
                                    React.createElement("span", { className: 'px-[3px]', style: { fontWeight: 600 } }, "Copy Link")),
                                React.createElement(Tooltip, { className: '!bg-black font-medium', anchorSelect: '.monitoringCopyLink', place: 'right', opacity: 1 }, "Copy the current filter, sort and item selection as a shareable link"))), pagination: React.createElement(Pagination, Object.assign({}, paginationProps, { ariaLabels: paginationLabels })), preferences: React.createElement(CollectionPreferences, { title: "Preferences", confirmLabel: "Confirm", cancelLabel: "Cancel", disabled: false, preferences: preferences, onConfirm: ({ detail }) => {
                                setPreferences(detail);
                                setRenderCount(renderCount + 1);
                            }, pageSizePreference: {
                                title: 'Page Size',
                                options: MonitoringListTablePageSizeOptions
                            }, wrapLinesPreference: {
                                label: 'Wrap Lines',
                                description: 'If checked, lines will be wrapped to display all of the text'
                            }, visibleContentPreference: {
                                title: 'Visible Columns',
                                options: MonitoringListTableVisibleContentOptions
                            } }) })))) }));
};
export default MonitoringList;
