export const getCloudTenantSchemas = (existingSchemas) => {
    if (existingSchemas) {
        existingSchemas.push({
            uri: "https://kivera.io/schemas/cloud-tenant-object-item",
            schema: {
                type: 'object',
                required: ["IsTenant"],
                properties: {
                    Name: { type: 'string' },
                    Nested: {
                        type: "array",
                        items: {
                            type: "string"
                        }
                    },
                    IsTenant: { type: 'boolean' },
                }
            }
        }, {
            uri: "https://kivera.io/schemas/cloud-tenant-object",
            fileMatch: ["*"],
            schema: {
                type: "object",
                patternProperties: {
                    "[A-Za-z0-9-]+": {
                        $ref: "https://kivera.io/schemas/cloud-tenant-object-item" // reference the second schema
                    }
                }
            }
        });
    }
    return existingSchemas;
};
